import React, {useState, useEffect} from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import  {getHttpCommon} from '../../httpCommon'
import UserContext from './../Context/SampleContext'
import { loginRequest } from "../../authConfig";
import {validateRole,getLanguage} from '../../Constants/Utilities';

export default class UpdateStore extends React.Component {
    static contextType = UserContext;

    constructor(props,context) {
        super(props,context)

        this.storeId = props.storeId;

        this.loginUserRoles = []
        if(context.accountObj[0]!=undefined){
        this.loginUserRoles =  context.accountObj[0].idTokenClaims?.roles;
        }
        this.mslInstance = context.msalObj;
        
        this.loginUserPermission = context.userPermission
        this.loginUserEmail = context.userEmail
        this.prefered_language = ["EN","RO","MX"]

        //UI Component State object
        this.state = {
            keepFlag: false,
            sendFlag: false,
            validated: false,
            risEnabled: false,
            brands: [],
            markets: [],
            marketGroup : [],
            preferedLanguage:"",
            keepOrSendDefault: "Keep",
            loginFailureAttempt: 0,
            isLocked:0,
            accessToken: ""
        }
    }

    componentDidMount() {

        var path = window.location.pathname.split('/');
        var storeId = this.storeId //path[2];

        this.mslInstance.acquireTokenSilent({
            ...loginRequest,
            account: this.loginUserRoles
        }).then((response) => {
          console.log(response.accessToken);
            //callMsGraph(response.accessToken).then(response => setGraphData(response));
            this.setState({ accessToken: response.accessToken })
            this.loadBrandDetails(response.accessToken);
            this.loadMarketDetails(response.accessToken);
            this.toLoadStoreDetails(storeId, response.accessToken)
        });
    }

    //To load Brand details for dropdown list
    loadBrandDetails(accessToken){
        getHttpCommon(accessToken).get('api/Brand')
        .then(res => {
            this.setState({
                brands: res.data
            });
        }).catch(function (error) {
            var response = error.response;
            var errorMessage = response.data.detail;
            alert(errorMessage);
        });
    }

    //To load Country/Market details
    loadMarketDetails(accessToken){
        this.setState({
            markets: []
        });
        getHttpCommon(accessToken).get('api/Market')
        .then(res => {
            this.setState({
                markets: res.data
            });
        }).catch(function (error) {
            var response = error.response;
            var errorMessage = response.data.detail;
            alert(errorMessage);
        });
    }

    getCountryGroup(accessToken, countryCode, cGroup = "", brandId){
        if(countryCode!=undefined && brandId != undefined){
            setTimeout(() => {
                this.setState({
                    marketGroup: []
                }); 
            
            getHttpCommon(accessToken).get('api/Market/Groups/' +countryCode + '/' + brandId)
            .then(res => {
                this.setState({
                    marketGroup : res.data
                });
                if(cGroup.length >0){
                    this.setState({
                        countryGrpName: cGroup,
                    });
                }
            }).catch(function (error) {
                var response = error.response;
                var errorMessage = response.data.detail;
                alert(errorMessage);
            });
        }, 100);
        }
    }

    toLoadStoreDetails(storeId, accessToken){
        getHttpCommon(accessToken).get('api/store/' + storeId)
        .then(res => {

            const store = res.data;
            this.setState({
                storeId: store.storeId,
                storeName: store.storeName,
                country: store.country,
                //countryGrpName: store.countryGrp,
                brandId: store.brandId,
                orisEnabled: store.orisEnabled,
                keepOrSendDefault: store.keepOrSendDefault,
                loginFailureAttempt: store.loginFailureAttempt,
                isLocked: store.isLocked,
                preferedLanguage:store.preferedLanguage
            });
            if (store.keepOrSendDefault === 'Keep')
                this.setState({ keepFlag: true, sendFlag: false });
            if (store.keepOrSendDefault === 'Send')
                this.setState({ keepFlag: false, sendFlag: true });

            this.getCountryGroup(this.state.accessToken, store.country, store.countryGrp, store.brandId)
        }).catch(function (error) {

            var response = error.response;
            var errorMessage = response.data.detail;
            alert(errorMessage);

        });
    }

    handleStoreIdChange = event => {
        this.setState({ storeId: event.target.value });
    }

    handleStoreNameChange = event => {
        this.setState({ storeName: event.target.value });
    }

    handleCountryChange = event => {
        this.setState({ country: event.target.value });
        //this.getCountryGroup(this.state.accessToken, event.target.value)

        //To Load group from API
        this.getCountryGroup(this.state.accessToken, event.target.value, "", this.state.brandId)
    }

    handleCGNameChange = event => {
        this.setState({ countryGrpName: event.target.value });
    }

    

    handleBrandIdChange = event => {
        this.setState({ brandId: event.target.value });

        //To Load group from API
        this.getCountryGroup(this.state.accessToken, this.state.country, "", event.target.value)
    }
    handleLanguageChange = event =>{
        this.setState({ preferedLanguage: event.target.value });

    }

    handleOrisEnabledChange = event => {
        this.setState({ orisEnabled: event.target.checked });
    }

    handleStoreLockedChange = event => {
        this.setState({ isLocked: event.target.checked });
        if(this.state.isLocked == true){
            this.setState({ loginFailureAttempt: 0 });    
        }
    }

    handleKeepOrSendDefaultChange = (event) => {
        if (event.target.value === 'Keep')
            this.setState({ keepFlag: true, sendFlag: false });
        if (event.target.value === 'Send')
            this.setState({ keepFlag: false, sendFlag: true });
        this.setState({ keepOrSendDefault: event.target.value });
    };

    backBtnClick = (event) => {
        event.preventDefault();
        //window.location.href = '/stores';
        this.props.callBackClose();
    };

    handleSubmit = event => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            this.setState({ validated: true });
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();

            const storeDetail = {
                storeId: this.state.storeId,
                storeName: this.state.storeName,
                country: this.state.country,
                countryGrp: this.state.countryGrpName,
                brandId: this.state.brandId,
                orisEnabled: this.state.orisEnabled,
                keepOrSendDefault: this.state.keepOrSendDefault,
                loginFailureAttempt: this.state.loginFailureAttempt,
                isLocked: this.state.isLocked,
                preferedLanguage: this.state.preferedLanguage
            }
            var path = window.location.pathname.split('/');
            var storeId = this.storeId //path[2];

            getHttpCommon(this.state.accessToken, this.loginUserEmail).put('api/store/' + storeId, storeDetail)
                .then(res => {
                    alert(res.data);
                    //window.location.href = '/stores';
                    //this.props.saveCallback("success");
                }).catch(function (error) {
                    var response = error.response;
                    var errorMessage = response.data;
                    alert(errorMessage);
                });
        }
    }

    render() {
        return (
            // <div className="container my-3">
            //     <h4>Update Store</h4>
            //     <form onSubmit={this.handleSubmit}>
            //         <div className="form-group">
            //             <label htmlFor="inputlg">Store Id</label>
            //             <input type="text" name='storeId' className="form-control input-lg" value={this.state.storeId} onChange={this.handleStoreIdChange} />
            //         </div>
            //         <div className="form-group">
            //             <label htmlFor="inputlg">Store Name</label>
            //             <input type="text" name='storeName' className="form-control input-lg" value={this.state.storeName} onChange={this.handleStoreNameChange} />
            //         </div>
            //         <div className="form-group">
            //             <label htmlFor="inputlg">Country</label>
            //             <input type="text" name='country' className="form-control input-lg" value={this.state.country} onChange={this.handleCountryChange} />
            //         </div>
            //         <div className="form-group">
            //             <label htmlFor="inputlg">Brand Id</label>
            //             <input type="text" name='brandId' className="form-control input-lg" value={this.state.brandId} onChange={this.handleBrandIdChange} />
            //         </div>
            //         <div className="form-check" style={{ marginTop: '10px' }}>
            //             <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" checked={this.state.orisEnabled} onChange={this.handleOrisEnabledChange} />
            //             <label className="form-check-label" for="defaultCheck1">
            //                 Oris Enabled
            //             </label>
            //         </div>
            //         <div className='form-group'>
            //             <label htmlFor="inputlg">Keep Or Send Default</label>
            //         </div>
            //         <div className="form-check">
            //             <input className="form-check-input" type="radio" name="exampleRadios" id="idRadioKeep" checked={this.state.keepFlag} value="Keep" onChange={this.handleKeepOrSendDefaultChange} />
            //             <label className="form-check-label" for="exampleRadios1">Keep</label>
            //         </div>
            //         <div className="form-check">
            //             <input className="form-check-input" type="radio" name="exampleRadios" id="idRadioSend" checked={this.state.sendFlag} value="Send" onChange={this.handleKeepOrSendDefaultChange} />
            //             <label className="form-check-label" for="exampleRadios2">Send</label>
            //         </div>
            //         <div className="form-group">
            //             <button type="submit" className="btn btn-primary btn-sm" style={{ marginTop: '10px' }}>Update Store</button>
            //         </div>
            //     </form>
            //     <button className="btn btn-primary btn-sm" onClick={this.backBtnClick} style={{ marginTop: '10px' }}>Back to Stores</button>
            // </div>
            <div id="container">
                <div>
                    <div style={{ marginTop: '20px' }}>
                        <div className="info-form" style={{ marginTop: '20px', width: '50%', margin: 'auto' }}>
                            <h4>Update Store</h4>
                            <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                                <Form.Group>
                                    <Form.Label>Store Id</Form.Label>
                                    <Form.Control
                                        required
                                        disabled = {!validateRole(this.loginUserPermission, ["HM_SADMIN","HM_PADMIN"])} 
                                        type="text"
                                        placeholder="Store Id"
                                        value={this.state.storeId}
                                        onChange={this.handleStoreIdChange}
                                        style={{ backgroundColor: '#fff' }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide Store Id
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Store Name</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Store Name"
                                        value={this.state.storeName}
                                        onChange={this.handleStoreNameChange}
                                        className="notranslate"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide Store name
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Country</Form.Label>
                                    <Form.Control as="select" placeholder="Store" required 
                                    value={this.state.country} 
                                    onChange={this.handleCountryChange} 
                                    disabled={!validateRole(this.loginUserPermission, ["HM_SADMIN","HM_PADMIN"])} style={{ backgroundColor: '#fff' }}>
                                        <option value=''>--Select a Country--</option>
                                        {this.state.markets.map((item) => (
                                            <option className="notranslate" value={item.country}>{item.country}</option>
                                        ))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please select Country
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Brand</Form.Label>
                                    <Form.Control as="select" placeholder="Store" required value={this.state.brandId} onChange={this.handleBrandIdChange} 
                                    disabled = {!validateRole(this.loginUserPermission, ["HM_SADMIN","HM_PADMIN"])} style={{ backgroundColor: '#fff' }} >
                                        <option value=''>--Select a Brand--</option>
                                        {this.state.brands.map((item) => (
                                            <option className="notranslate" value={item.brandId}>{item.name}</option>
                                        ))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please select Brand
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Country Group</Form.Label>
                                    <Form.Control as="select" 
                                        value={this.state.countryGrpName}
                                        disabled={!validateRole(this.loginUserPermission, ["HM_SADMIN","HM_PADMIN"])}
                                        placeholder="Store" required onChange={this.handleCGNameChange} >
                                        <option value=''>--Select a Country Group--</option>
                                        {this.state.marketGroup.map((item) => (
                                            <option className="notranslate" value={item.countryGrp}>{item.countryGrp}</option>
                                        ))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please select Country
                                    </Form.Control.Feedback>
                                </Form.Group>
                                
                                {validateRole(this.loginUserPermission, ["HM_SADMIN","HM_PADMIN","HM_ADMIN"])==true?
                                <Form.Label style={{ marginTop: '10px'}}>Keep Or Send Default</Form.Label>:null}
                                {validateRole(this.loginUserPermission, ["HM_SADMIN","HM_PADMIN","HM_ADMIN"])==true?<Form.Group className="mb-3">
                                    <Form.Check disabled= {!validateRole(this.loginUserPermission, ["HM_SADMIN","HM_PADMIN"])} type="radio" id="idRadioKeep" name="exampleRadios" label="Keep" value="Keep" checked={this.state.keepFlag} onChange={this.handleKeepOrSendDefaultChange} />
                                    <Form.Check disabled= {!validateRole(this.loginUserPermission, ["HM_SADMIN","HM_PADMIN"])} type="radio" id="idRadioSend" name="exampleRadios" label="Send" value="Send" checked={this.state.sendFlag} onChange={this.handleKeepOrSendDefaultChange} />
                                </Form.Group>:null}
                                {validateRole(this.loginUserPermission, ["HM_SADMIN","HM_PADMIN","HM_ADMIN"])==true?<Form.Group className="mb-3" style={{ marginTop: '10px', display: 'none' }}>
                                    <Form.Check reverse="true" type="checkbox" label="Is Store Locked?" checked={this.state.isLocked}
                                        onChange={this.handleStoreLockedChange}/>
                                </Form.Group>: null}
                                {validateRole(this.loginUserPermission, ["HM_SADMIN","HM_PADMIN"])==true?<Form.Group className="mb-3" style={{ marginTop: '20px', display:'visible' }}>
                                    <Form.Check type="checkbox" label="Oris Enabled" checked={this.state.orisEnabled}
                                        onChange={this.handleOrisEnabledChange} />
                                </Form.Group>:null}
                                <div style={{ marginTop: '30px',marginBottom: '20px', textAlign:"center"}}>
                                    <button className="btnNormalBkp" type="submit" style={{ marginTop: '10px' }}>Save</button>
                                    <span className="btnNormal" type="submit" onClick={this.backBtnClick} style={{ marginTop: '10px', marginLeft: '10px' }}>Close</span>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}