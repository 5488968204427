import React, { Component } from 'react';
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { getHttpCommon} from '../../httpCommon';
import {validateRole } from '../../Constants/Utilities';
import SampleContext from '../Context/SampleContext';

import { loginRequest } from "../../authConfig";
import LoadingSpinner from '../../LoadingSpinner';
import Pagination from '../../components/Pagination';
import QRCode from "react-qr-code";
import { set } from 'date-fns';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';


export default class PendingItems extends Component {
  static contextType = SampleContext;

  activeButton = "";
  
  constructor(props, context) {
    super(props, context)

    var { storeDet } = context;

    this.state = {
      unhandledPIList: [],
      pendingItemsList: [],
      accessToken: "",
      isLoading: false,
      filterInput: "",
      pendingItemsBase: [],
      currentPage: 1,
      storeId: "",
      openDialogQR: false,
      itemGTIN:"",
      itemNumber:"",
      isHandlePopup:false,
      selReasonCode:"",
      selReason: "",
      isNormalPI: true,
      pageTitle: "Pending Items in",
      PIStatus: "O",
    }

    this.loginUserRoles = []
    if (context.accountObj[0] != undefined) {
      this.loginUserRoles = context.accountObj[0].idTokenClaims?.roles;
    }
    this.mslInstance = context.msalObj;
    this.loginUserPermission = context.userPermission
    this.loginUserEmail = context.userEmail,
      this.inputDisable = true
    this.pageSize = 15

  }


  componentDidMount() {
    this.setState({ isLoading: true })
    this.mslInstance.acquireTokenSilent({
      ...loginRequest,
      account: this.loginUserRoles
    }).then((response) => {
      console.log(response.accessToken);
      this.setState({ accessToken: response.accessToken })
      //this.loadStoreList(response.accessToken)
      //this.loadPendingItemList("")
      this.setState({isLoading:false})
    });
  }
  handleClose = () => {
    this.setState({ openDialogQR: false });
  }
  handleOpen = (GTIN, ItemNumber) => {  
    this.setState({ openDialogQR: true });
    this.setState({ itemGTIN: GTIN });
    this.setState({ itemNumber: ItemNumber });
  }  



  //To load Store Details 
  loadPendingItemList(storeId, isNormalPIVal) {

    //TODO: ***** Validate the ROLE and build the Route *****
    var routePath = 'api/Box/GetPendingItems/' + storeId + "/" + this.loginUserEmail;
    if(isNormalPIVal==false){
      routePath = 'api/Box/GetUnhandledPendingItems/' + storeId + "/" + this.loginUserEmail;
    }
    this.setState({storeId: storeId});
    this.setState({ pendingItemsList: [] });
    this.setState({ unhandledPIList: [] });

    getHttpCommon(this.state.accessToken).get(routePath)
      .then(res => {
        const pitemList = res.data;
        if(this.state.isNormalPI==true){
          this.setState({ pendingItemsList: pitemList });
          this.setState({ pendingItemsBase: pitemList })
        }else{
          this.setState({ unhandledPIList: pitemList });
          this.setState({ unhandledPIList: pitemList })
        }
        this.setState({ isLoading: false })
      }).catch(function (error) {
        var response = error.response;
        var errorMessage = response.data.detail;
        //this.handleError();
        //alert(errorMessage + ", or Please enter a valid store ID");
        alert("No data found for the store");
      });
  }


  handleError(){
    alert("test")
    this.setState({ pendingItemsList: []});
    this.setState({ pendingItemsBase: []})
  }

  //To Delete selected store in the list
  showItemQR(item) {
    //alert(JSON.stringify(item));
    if(item.gtin!=null && item.gtin.length >0){    
      var proceed = window.confirm("Please use ORIS 3.1 app item handling screen to handle this item. ");
      if (proceed) {
        //Write code here to show ITEM QR code
        this.handleOpen(item.gtin, item.orderLineItemId);
      } 
      else {
      }
    }
    else{
      alert("Unfortunately, this item dont have GTIN number, Please contact ORIS tech support team or Create Incident.");
    }
  }

  triggerMarkUnhandle=(index, item) =>{
    //alert(this.activeButton)

    if(document.getElementById(this.activeButton)!=undefined && !this.activeButton.endsWith(index)){
        document.getElementById(this.activeButton).click();     
    }

    if(this.activeButton.endsWith(index))
    {
      this.activeButton = "";
    }
    else
    {
      this.activeButton = "test" + index
      this.setState({ selReasonCode: "" });
      this.setState({ seldReason: ""});
      //alert("assigned")
    }    
}
 

  markUnhandle = (item, index) => {
    this.setState({isHandlePopup:true})
    var buttonName= "test" + index;

    document.getElementById(buttonName).click();

    this.activeButton  = ""

   var routePath = "api/Box/MarkUnhandleItem?id=" + item.transId + "&reasonCode=" + this.state.selReasonCode + "&reason="+ this.state.selReason+ "&handledBy=" + this.loginUserEmail;
    getHttpCommon(this.state.accessToken).get(routePath)
      .then(res => {
        alert("Success")
        const newList = this.state.pendingItemsList.filter((i) => i.transId !== item.transId);
        this.setState({ pendingItemsList: newList })
        this.setState({ isLoading: false })
      }).catch(function (error) {
        var response = error.response;
        //var errorMessage = response.data.detail;
        //this.handleError();
        //alert(errorMessage + ", or Please enter a valid store ID");
        alert("Unable to mark this pending item unhandled");
      });
  }

  handleFilterChange = (event) => {

    this.setState({ filterValue: event.target.value });
    this.setState({ filterInput: "" });

    if (event.target.value == "filter") {
      this.inputDisable = true
      this.setState({ stores: this.state.pendingItemsBase })
    }
    else {
      this.inputDisable = false
      this.setState({ stores: this.state.pendingItemsBase })
    }
  }

  handleFilterText = (event) => {
    this.setState({ filterInput: event.target.value })
  }

  currentTableData = () => {
    let firstPageIndex = (this.state.currentPage - 1) * this.pageSize;
    let lastPageIndex = firstPageIndex + this.pageSize;

    if(this.state.isNormalPI == true){
      return this.state.pendingItemsList.slice(firstPageIndex, lastPageIndex);
    }
    else{
      return this.state.unhandledPIList.slice(firstPageIndex, lastPageIndex);
    }
  }

  setCurrentPage(page) {
    this.setState({ currentPage: page });
  }

  handleStoreChange = (event) => {
    if (event.target.value != "filter") {
      this.loadPendingItemList(event.target.value, this.state.isNormalPI)
      this.setState({ storeId: event.target.value });
    }
  }

  handleReasonCode = (event) => {
    if(event.target.value != 0){
      var e = document.getElementById("selReasonControl");
      var txt=e.options[e.selectedIndex].text;
      this.setState({ selReasonCode: event.target.value });
      this.setState({ selReason: txt});
    }
  }

  handlePIStatus = (changeEvent) => {
    this.setState({ PIStatus: changeEvent.target.value });
    var isCurrentVal = false
    if(changeEvent.target.value =='O'){
      this.setState({ isNormalPI: true});
      this.setState({pageTitle: "Pending Items in"})
      isCurrentVal = true
    }else{
      this.setState({ isNormalPI: false});
      this.setState({pageTitle: "Unhandle Marked items in"})
      isCurrentVal = false
    }
    this.setState({ currentPage: 1})
    if (this.state.storeId != "filter" && this.state.storeId.length>0) {
      this.loadPendingItemList(this.state.storeId, isCurrentVal)
    }
  }


  render() {
    const { pendingItemsList, currentPage } = this.state;

    return (
      <div className="containerStyle">
        <div className="searchBox" style={{ width: '250px' }}>
            <div className="searchBoxLabel"  style={{ display: 'none' }}>Search Pending Items</div>
            <div class="searchBoxContainer" style={{ display: 'none' }}>
              <input className="storeIdBox" onChange={this.handleFilterText} disabled={false} value={this.state.filterInput} placeholder="Enter Store ID"></input>
              <span className="searchButton" onClick={() => this.loadPendingItemList(this.state.filterInput, this.state.isNormalPI)}>Search</span>
            </div>
            <span>
              <SampleContext.Consumer>
                  {storeDet =>{
                    if(storeDet.userStoreList != null)
                    {
                          return <Form.Group style={{width:"500px"}}>
                        <Form.Label><b>Choose store </b></Form.Label>
                        <Form.Control as="select" placeholder="Store" required 
                        value={this.state.storeId} 
                        onChange={this.handleStoreChange} 
                          style={{ backgroundColor: '#fff',width:"200px" }} >
                            <option value=''>--Select a Store--</option>
                            {storeDet.userStoreList.map((store, index) => (
                                store.isSendFlowEnabled==true?
                                <option key={index} value={store.storeId}>{store.storeId}</option>:null
                                
                            ))}
                            
                        </Form.Control>
                        <div class="question-box">
                          <div class="question-box__question">
                            <input class="question__input" id="r1" type="radio" name="web" value="O"
                            checked={this.state.PIStatus === 'O'} 
                            onChange={this.handlePIStatus} ></input>
                            <label class="question__label" for="r1">Pending Items</label>

                            <input class="question__input" id="r2" type="radio" name="web" value="C"
                            checked={this.state.PIStatus === 'C'} 
                            onChange={this.handlePIStatus} ></input>
                            <label class="question__label" for="r2">Unhandled Marked items</label>
                          </div>
                        </div>
                    </Form.Group>
                    }
                  }}
              </SampleContext.Consumer>
            </span>
        </div>
        <div className="subHeaderContainer">
          <div className="subTitle subTitleMore">{this.state.pageTitle} {this.state.storeId} ({this.state.pendingItemsList.length})</div>
          < div className="subButtonSec">
               <select style={{ margin: '10px', padding: '2px', display: 'none' }}
              value={this.state.filterValue}
              onChange={this.handleFilterChange}>
              <option value="filter">Filter By</option>
              <option value="storeId">Store Id</option>
              <option value="country">Country</option>
              <option value="brandName">Brand Name</option>
            </select>
           
            <Button disabled={!this.state.pendingItemsList.length >0} onClick={(e) => this.sendMessageToAll(e)} style={{ margin: '10px', display:'none' }}>Send Message to All</Button>
          </div>
          <Modal show={this.state.openDialogQR} className='ModalStyle' 
          >
            <Modal.Header className="modelHeaderBG size16" >
              <Modal.Title>Scan this QR for the item ({this.state.itemNumber})</Modal.Title>
              <span className="closeIconStyle2 size16" onClick={() => this.handleClose()}>&times;</span>
            </Modal.Header>
            <Modal.Body>
              <div className= "errContent">
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "200px", width: "200px" }}
                  value={this.state.itemGTIN}
                  viewBox={`0 0 256 256`}
                  />
              </div>
            </Modal.Body>
          </Modal>
        </div>

        {this.state.isLoading ? LoadingSpinner(this.state.isLoading) :
          this.state.isNormalPI == true?
          <Table striped bordered hover>
            <thead>
              <tr className="tableHeader">
                <th>Box ID</th>
                <th>Item Number</th>
                <th>Item Number</th>
                <th>Article Desc</th>
                <th>Color</th>
                <th>Size</th>
                <th>Price</th>
                <th>Show Item QR</th>
              </tr>
            </thead>

            {this.state.pendingItemsList.length > 0 ?
            <tbody>
              {this.currentTableData().map((item, index) => (
                <tr key={index} className='tableRow notranslate'>
                  <td>{item.boxId}</td>
                  <td>{index}</td>
                  <td>{item.orderLineItemId}</td>
                  <td style={{ minWidth: '280px'}}>{item.articleDesc}</td>
                  <td>{item.lineItemColor}</td>
                  <td>{item.lineItemSize}</td>
                  <td>{item.itemOnlinePrice}</td>
                  <td style={{ minWidth: '320px'}}>
                  <span className="unblockBtn translate" onClick={(e) => this.showItemQR(item)}>Show QR</span>
                  <OverlayTrigger trigger="click" placement="bottom"  overlay={
                        <div className="OModelContainer">
                          <div className="title">Choose a reason</div>
                          <div className="content">
                            <div>
                              <select id="selReasonControl" onChange={this.handleReasonCode} >
                                <option value="0">[Select]</option>
                                <option value="1">Item sold</option>
                                <option value="2">Item lost in store</option>
                                <option value="3">Item sent to warehouse</option>
                                <option value='4'>Item did not fit the box</option>
                              </select>
                            </div>
                            <div className="buttonOverlayBox">
                              <span className="btnNormal translate" onClick={(e) => { if(this.state.selReasonCode.length>0){
                                this.markUnhandle(item, index)} else {}
                                }}>Ok</span>
                            </div>
                          </div>
                        </div>}>
                      <span className="btnNormal translate" onClick= {e => {this.triggerMarkUnhandle(index, item)}} id={"test"+index}>Mark Unhandled</span>
                  </OverlayTrigger>
                 </td>
                </tr>
              ))}
              </tbody> : <tr>
                  <td colSpan={6}><p className="errorMessage">Not Records Yet. Please enter store id and search</p></td>
                  </tr>}
          </Table>:
          <Table striped bordered hover>
           <thead>
            <tr className="tableHeader">
              <th>Store Id</th>
              <th>Item Number</th>
              <th>Article Desc</th>
              <th>Item price</th>
              <th>Suspended By</th>
              <th>Suspended On</th>
              <th>Reason</th>
            </tr>
          </thead>
          {this.state.unhandledPIList.length > 0 ?
            <tbody>
              {this.currentTableData().map((item, index) => (
                <tr key={index} className='tableRow notranslate'>
                  <td>{item.storeId}</td>
                  <td>{item.orderLineItemId}</td>
                  <td style={{ minWidth: '280px'}}>{item.articleDesc}</td>
                  <td>{item.itemOnlinePrice}</td>
                  <td>{item.suspendedBy}</td>
                  <td>{item.suspendedOn}</td>
                  <td>{item.reasonDesc}</td>
                </tr>
              ))}
              </tbody> : <tr>
                  <td colSpan={6}><p className="errorMessage">Not Records Yet. Please enter store id and search</p></td>
                  </tr>}
          </Table>
          }
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={pendingItemsList.length}
          pageSize={this.pageSize}
          onPageChange={page => this.setCurrentPage(page)}
        />
      </div>
    )
  }
}